// @mixin button-variant($background, $border, $active-background, $active-border)

.btn {
    &:not(.btn-link) {
        text-transform: uppercase;
    }

    &.lower-case {
        text-transform: none;
    }

    &-primary {
        @include button-variant($patina, $patina, $eden, $eden);

        &.disabled,
        &:disabled {
            background-color: $gray-light;
            border-color: $gray-medium;
            color: $gray-dark;
        }
    }

    &:focus,
    &.focus {
        @if $enable-shadows {
            box-shadow: $input-btn-focus-box-shadow;
        }
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show > &.dropdown-toggle {
        @if $enable-shadows {
            box-shadow: $input-btn-focus-box-shadow;
        }
    }
}
