// @mixin alert-variant($background, $border, $color)

.alert-danger {
    @include alert-variant($red-light, $red-light, $body-color);
}

.alert-info {
    @include alert-variant($blue-light, $blue-light, $body-color);
}

.alert-light {
    @include alert-variant($gray-light, $gray-light, $body-color);
}

.alert-success {
    @include alert-variant($green-light, $green-light, $body-color);
}

.alert-warning {
    @include alert-variant($orange-light, $orange-light, $body-color);
}

// Embedded icons

.alert-danger,
.alert-info,
.alert-success,
.alert-warning {
    padding-left: 3.5rem;
    background-repeat: no-repeat;
    background-size: 2em 2em; // MSIE requires two-value syntax
    background-position-x: 1rem;
    background-position-y: 0.45rem;
}

.alert-danger {
    @include avi-background-image-cancel(theme-color(danger));
}

.alert-info {
    @include avi-background-image-info(theme-color(info));
}

.alert-success {
    @include avi-background-image-check-circle(theme-color(success));
}

.alert-warning {
    @include avi-background-image-warning(theme-color(warning));
}
