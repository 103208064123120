//
// Button input groups
//

.input-group {
    .btn-link {
        background-color: $input-bg;
    }

    // Suppress border-left of an input following a button addon
    .input-group-prepend + .form-control {
        border-left-color: transparent;
    }

    // Suppress border-right of an input followed by a button addon
    .form-control:not(:last-child) {
        border-right-color: transparent;
    }

    // Suppress border-right of .btn-link followed by an input
    .input-group-prepend .btn-link:last-child {
        border-right-color: transparent;
    }

    // Suppress border-left of .btn-link following an input
    .input-group-append,
    input:focus ~ .input-group-append {
        .btn-link:first-child {
            border-left-color: transparent;
        }
    }

    // Highlight button on input focus - only works for successors
    input:focus ~ .input-group-append {
        .btn-link {
            border-color: $input-focus-border-color;
        }

        .btn-primary {
            background-color: $eden;
            border-color: $eden;
        }
    }

    // Some special treatment for .btn-link in form validation
    .form-control {
        .was-validated &:invalid,
        &.is-invalid {
            ~ .input-group-append {
                .btn-link {
                    border-color: $form-feedback-invalid-color;

                    &:first-child {
                        border-left-color: transparent;
                    }
                }
            }
        }

        .was-validated &:invalid:focus,
        &.is-invalid:focus {
            ~ .input-group-append {
                .btn-link {
                    border-color: $input-focus-border-color;

                    &:first-child {
                        border-left-color: transparent;
                    }
                }
            }
        }
    }
}

.input-group-prepend,
.input-group-append {
    > .btn {
        box-shadow: $input-box-shadow;

        &.btn-link {
            border-color: $input-border-color;
        }

        &.btn-primary:not(:disabled):not(.disabled) {
            border-color: $patina;

            &:hover {
                border-color: $eden;
            }
        }
    }
}
