body {
    // Better Font Rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b,
strong,
label {
    font-weight: $font-weight-bold;
}

button:focus {
    outline: none;
}
