/*
 * Custom .navbar variant
 *
 * Usage like
 * .navbar.avi-navbar-variant-1
 */
.avi-navbar-variant-1 {
    $variants: dark light;

    .navbar-nav {
        .show > .nav-link,
        .active > .nav-link,
        .nav-link.show,
        .nav-link.active {
            background-color: transparent; // Reset
        }
    }

    // Color variants
    @each $variant in $variants {
        &.navbar-#{$variant} {
            .navbar-nav {
                .nav-link {
                    color: avi-nav-color-variant(link, $variant);

                    @include hover-focus {
                        color: avi-nav-color-variant(hover, $variant);
                    }

                    &.disabled {
                        color: avi-nav-color-variant(disabled, $variant);
                    }
                }

                .show > .nav-link,
                .active > .nav-link,
                .nav-link.show,
                .nav-link.active {
                    color: avi-nav-color-variant(active, $variant);
                }
            }
        } // &.navbar-#{$variant}
    } // @each $variant in $variants

    // Responsive navbar
    //
    // Custom styles for responsive collapsing and toggling of navbar contents.
    // Powered by the collapse Bootstrap JavaScript plugin.

    // Generate series of `.navbar-expand-*` responsive classes for configuring
    // where your navbar collapses.
    &.navbar-expand {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);

            &#{$infix} {
                // Compact (small screens below expand breakpoint)
                // Left border indicates active item
                @include media-breakpoint-down($breakpoint) {
                    .navbar-nav {
                        .show > .nav-link,
                        .active > .nav-link,
                        .nav-link.show,
                        .nav-link.active {
                            &:not(.dropdown-toggle) {
                                border-left: $avi-navbar-variant-1-active-border-width
                                    solid; // Applies text color
                                padding-left: $navbar-padding-x -
                                    $avi-navbar-variant-1-active-border-width;
                            }
                        }
                    }
                }

                // Expanded (above expand breakpoint)
                // Bottom border indicates active item
                @include media-breakpoint-up($next) {
                    .navbar-nav {
                        .show > .nav-link,
                        .active > .nav-link,
                        .nav-link.show,
                        .nav-link.active {
                            &:not(.dropdown-toggle) {
                                border-bottom: $avi-navbar-variant-1-active-border-width
                                    solid; // Applies text color
                                padding-bottom: $navbar-padding-y +
                                    $nav-link-padding-y -
                                    $avi-navbar-variant-1-active-border-width;
                            }
                        }
                    }
                }
            }
        }
    } // &.navbar-expand
} // .avi-navbar-variant-1

/*
 * Custom .navbar variant
 *
 * Usage like
 * .navbar.avi-navbar-variant-2
 */
.avi-navbar-variant-2 {
    $variants: dark light;

    .navbar-nav {
        .show > .nav-link,
        .active > .nav-link,
        .nav-link.show,
        .nav-link.active {
            background-color: transparent; // Reset
        }

        .nav-item {
            @include media-breakpoint-up(lg) {
                font-size: $navbar-nav-nav-item-font-size;
            }
        }

        .nav-link {
            font-weight: $font-weight-base;
            padding: (
                    $navbar-padding-y + $avi-navbar-variant-2-nav-link-padding-y
                )
                $nav-link-padding-x;
        }
    }

    // Color variants
    @each $variant in $variants {
        &.navbar-#{$variant} {
            .navbar-nav {
                .nav-link {
                    color: avi-nav-color-variant(link, $variant);

                    @include hover-focus {
                        color: avi-nav-color-variant(hover, $variant);
                    }

                    &.disabled {
                        color: avi-nav-color-variant(disabled, $variant);
                    }
                }

                .show > .nav-link,
                .active > .nav-link,
                .nav-link.show,
                .nav-link.active {
                    color: avi-nav-color-variant(active, $variant);
                }
            }
        } // &.navbar-#{$variant}
    } // @each $variant in $variants

    // Responsive navbar
    //
    // Custom styles for responsive collapsing and toggling of navbar contents.
    // Powered by the collapse Bootstrap JavaScript plugin.

    // Generate series of `.navbar-expand-*` responsive classes for configuring
    // where your navbar collapses.
    &.navbar-expand {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);

            &#{$infix} {
                // Compact (small screens below expand breakpoint)
                // Left border indicates active item
                @include media-breakpoint-down($breakpoint) {
                    .navbar-nav {
                        .show > .nav-link,
                        .active > .nav-link,
                        .nav-link.show,
                        .nav-link.active {
                            &:not(.dropdown-toggle) {
                                border-left: $avi-navbar-variant-2-active-border-width
                                    solid; // Applies text color
                                padding-left: $navbar-padding-x -
                                    $avi-navbar-variant-2-active-border-width;
                            }
                        }
                    }
                }

                // Expanded (above expand breakpoint)
                // Bottom border indicates active item
                @include media-breakpoint-up($next) {
                    .navbar-nav {
                        .show > .nav-link,
                        .active > .nav-link,
                        .nav-link.show,
                        .nav-link.active {
                            &:not(.dropdown-toggle) {
                                border-bottom: $avi-navbar-variant-2-active-border-width
                                    solid; // Applies text color
                                padding-bottom: $navbar-padding-y +
                                    $avi-navbar-variant-2-nav-link-padding-y -
                                    $avi-navbar-variant-2-active-border-width;
                            }
                        }
                    }
                }
            }
        }
    } // &.navbar-expand
} // .avi-navbar-variant-2

// ===== Deprecated ============================================================

// ===== Deprecated in v1.4 ====================================================

// CSS Variables (Custom Properties) for dynamic calculation
:root {
    --avi-navbar-height: avi-navbar-height(sm);

    @include media-breakpoint-up(md) {
        --avi-navbar-height: avi-navbar-height(md);
    }

    @include media-breakpoint-up(lg) {
        --avi-navbar-height: avi-navbar-height(lg);
    }
}

// Navbar dimensioning base
.mt-avi-navbar {
    margin-top: avi-navbar-height(sm);
}
.pt-avi-navbar {
    padding-top: avi-navbar-height(sm);
}
.avi-navbar {
    min-height: avi-navbar-height(sm);
}

// Navbar dimensioning md
@include media-breakpoint-up(md) {
    .mt-avi-navbar {
        margin-top: avi-navbar-height(md);
    }
    .pt-avi-navbar {
        padding-top: avi-navbar-height(md);
    }
    .avi-navbar {
        min-height: avi-navbar-height(md);
    }
}

// Navbar dimensioning lg
@include media-breakpoint-up(lg) {
    .mt-avi-navbar {
        margin-top: avi-navbar-height(lg);
    }
    .pt-avi-navbar {
        padding-top: avi-navbar-height(lg);
    }
    .avi-navbar {
        min-height: avi-navbar-height(lg);
    }
}

// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.avi-navbar.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
            }

            @include media-breakpoint-up($next) {
                // Offset calculation formula:
                //
                // offset-y = ( navbar-height - ( font-size * line-height ) ) / 2
                //
                // Attempting to perform a calculation in Sass with a value from map-get, f.e.
                // map-get($avi-navbar-heights, $next) + 2rem, results in "Error: Invalid null
                // operation" 😖
                // So here's a workaround with css calc:

                // Fallback
                $margin-y: calc(
                    (
                            #{avi-navbar-height($next)} - #{$h3-font-size * $line-height-base}
                        ) / -2
                );
                $padding-y: calc(
                    (
                            #{avi-navbar-height($next)} - #{$h3-font-size * $line-height-base}
                        ) / 2
                );

                .navbar-nav {
                    margin-top: $margin-y;
                    margin-bottom: $margin-y;

                    .nav-link {
                        padding-top: $padding-y;
                        padding-bottom: $padding-y;
                    }
                }

                // Utilizing CSS Variables (Custom Properties)
                $margin-y: calc(
                    (
                            var(--avi-navbar-height) -
                                (
                                    var(--navbar-nav-nav-item-font-size) * #{$line-height-base}
                                )
                        ) / -2
                );
                $padding-y: calc(
                    (
                            var(--avi-navbar-height) -
                                (
                                    var(--navbar-nav-nav-item-font-size) * #{$line-height-base}
                                )
                        ) / 2
                );

                .navbar-nav {
                    margin-top: $margin-y;
                    margin-bottom: $margin-y;

                    .nav-link {
                        padding-top: $padding-y;
                        padding-bottom: $padding-y;
                    }
                }
            }
        }
    }
}

.avi-navbar {
    .navbar-brand {
        background: $avi-icon-background-image left top no-repeat;
        background-size: contain;
        padding-left: 56px;

        @include media-breakpoint-up(md) {
            background-image: $avi-branding-background-image;
            height: 40px;
            line-height: 35px;
            margin: 2px 0 0;
            padding: 0px;
            padding-left: 150px;
        }
    }
}

.avi-navbar-toggler {
    font-size: 30/16 * 1rem;
    line-height: 1;
    background-color: transparent;
    color: $patina;
    cursor: pointer;
    min-width: 32/30 * 1em; // Prevent flickering
    display: inline-block; // Normalize a & button
    padding: 1px; // Normalize a & button
    border: none; // Normalize a & button

    &:hover {
        color: $eden;
    }
}

.avi-navbar-nav-user {
    .dropdown-toggle:after {
        display: none;
    }

    // Adjust positioning for .dropdowns' .dropdown-menu
    .dropdown-menu {
        left: auto;
        right: 0;
    }
}
