//
// Basic Bootstrap table
//

.table {
    th {
        font-weight: 500;
    }

    th,
    td {
        border-top: none;
    }

    thead th {
        border-bottom-width: 1px;
    }

    &:not(.table-dark) thead {
        th {
            background-color: $white;
        }
    }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
    border: none;

    th,
    td {
        border-top: $table-border-width solid $table-border-color;
        border-bottom: none;
        border-left: none;
        border-right: none;
    }

    thead {
        th,
        td {
            border: none;
        }
    }
}
