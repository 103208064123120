@import 'styles/bootstrap-overrides/_colors';

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    color:$eden !important;
}
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-bottom:1px solid $eden !important;
    color:$eden !important
}
#CybotCookiebotDialogDetailBulkConsentLink:hover {
    color:$eden !important
}
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    border:2px solid $eden !important;
}
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    border-color:$eden !important;
}
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color:$eden !important;
}
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderButton,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleDeselectVendorsLink,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleSelectVendorsLink {
    border:2px solid $eden !important;
}
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderButton.select,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleSelectVendorsLink {
    background-color:$eden !important;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a {
    color:$eden !important;
}
#CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color:$eden !important
}
#CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover {
    color:$eden !important
}
@media screen and (min-width:601px) {
    #CybotCookiebotDialogTabContent input:focus+.CybotCookiebotDialogBodyLevelButtonSlider {
        box-shadow:0 0 1px $eden !important
    }
}
#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentIABv2Tabs input[type=checkbox]:checked {
    background-color:$eden !important;
    border-color:$eden !important
}
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
    color:$eden !important
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentTextAbout a {
    color:$eden !important;
}
@media screen and (min-width:601px) {
    #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input:focus-visible+span,
    #CybotCookiebotDialog :focus-visible {
        outline:2px solid $eden !important
    }
}
