//
// Docs color palette classes
//

@each $color, $value in $colors {
    .avi-swatch-#{$color} {
        color: color-yiq($value);
        background-color: #{$value};
    }
}

@each $color, $value in $theme-colors {
    .avi-swatch-#{$color} {
        color: color-yiq($value);
        background-color: #{$value};
    }
}

@each $color, $value in $grays {
    .avi-swatch-#{$color} {
        color: color-yiq($value);
        background-color: #{$value};
    }
}
