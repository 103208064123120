/**
 * _site-header-nav.scss
 */

// MSIE Flexbugs
//
//
// MSIE Flexbug #7: flex-basis doesn't account for box-sizing: border-box
//
// An explicit flex-basis value (i.e., any value other than auto) is supposed to
// act just like width or height. It determines the initial size of a flex item and
// then the other flexibility properties allow it to grow or shrink accordingly. IE
// 10-11 always assume a content box model when using flex-basis to determine a
// flex item's size, even if that item is set to box-sizing: border-box.
//
// Workaround: Instead of setting an explicit flex-basis value, use auto, and then
// set an explicit width or height.
//
// https://github.com/philipwalton/flexbugs#flexbug-7
//

// Layout
//

// Site header primary nav
// Primary navbar intended for launchpad toggle, filter bar, notifications.
// `-two-stages` means forced 2 rows wrapped on mobile, single row on `up(lg)`.
// Will affect the order of descendant nav's.
.avi-site-header-nav-primary,
.avi-site-header-nav-primary-two-stages {
    display: flex;
    flex-wrap: wrap;
    background-color: $avi-site-header-nav-primary-bg;
    color: $avi-site-header-nav-primary-color;
}

.avi-site-header-nav-aside-primary,
.avi-site-header-nav-aside-secondary,
.avi-site-header-nav-main {
    display: flex;
    align-items: center;
    max-width: 100%; // Limit to enable text-truncate on small screens
    padding: $avi-site-header-nav-padding-y $avi-site-header-nav-padding-x;

    > * {
        max-width: 100%; // Limit to enable text-truncate on small screens
    }

    .avi-site-header-nav-primary &,
    .avi-site-header-nav-primary-two-stages & {
        @each $breakpoint in xs md lg {
            @include media-breakpoint-up($breakpoint) {
                min-height: map-get(
                    $avi-site-header-nav-primary-base-heights,
                    $breakpoint
                );
            }
        }
    }
}

// Site header nav primary aside
// Intended for launchpad toggle & app title
.avi-site-header-nav-aside-primary {
    // background: lightcyan;  // Debug

    @media (min-width: $avi-site-content-aside-primary-width-md) {
        max-width: $avi-site-content-aside-primary-width-md; // Limit to enable truncate long app name on small screens
    }

    @include media-breakpoint-up(md) {
        width: $avi-site-content-aside-primary-width-md;
    }
}

// Site header nav secondary aside
// Intended for notifications & user menu
.avi-site-header-nav-aside-secondary {
    // background: lightgoldenrodyellow; // Debug
    order: 2;
    margin-left: auto;
    flex-direction: row-reverse;

    @media (min-width: $avi-site-content-aside-secondary-width-md) {
        max-width: $avi-site-content-aside-secondary-width-md;
    }

    @include media-breakpoint-up(md) {
        width: $avi-site-content-aside-secondary-width-md;
    }

    // Forced 2 rows wrapped on mobile, single row on `up(lg)`
    .avi-site-header-nav-primary-two-stages & {
        @include media-breakpoint-down(md) {
            order: 1;
        }
    }
}

// Site header nav main
// Intended for filter bar
.avi-site-header-nav-main {
    // background: lightpink;  // Debug
    order: 1;
    flex: 1 1 auto;
    padding: $avi-site-header-nav-padding-y 0;

    @include media-breakpoint-up(lg) {
        max-width: calc(
            100% - #{$avi-site-content-aside-primary-width-md} - #{$avi-site-content-aside-secondary-width-md}
        ); // Limit to prevent wrap
    }

    // Forced 2 rows wrapped on mobile, single row on `up(lg)`
    .avi-site-header-nav-primary-two-stages & {
        @include media-breakpoint-down(md) {
            order: 2;
            max-width: 100%;
            width: 100%;
            padding: $avi-site-header-nav-padding-y
                $avi-site-header-nav-padding-x;
        }
    }
}

// Component styles
//

// Container for launchpad toggle, Aviatar logotype & app title
.avi-site-header-nav-app {
    display: flex;
}

// Launchpad toggle
.avi-site-header-nav-launchpad-toggle {
    flex: 0 0 auto;

    // Reset button styles
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    background: $avi-icon-background-image 100% no-repeat;

    width: 36/16 * 1rem;
    height: 36/16 * 1rem;

    @include media-breakpoint-up(md) {
        width: 38/16 * 1rem;
        height: 38/16 * 1rem;
    }
}

// Aviatar logotype & app title
.avi-site-header-nav-heading {
    .avi-site-header-nav-launchpad-toggle + & {
        margin-left: $avi-site-header-nav-padding-x;
    }

    flex: 0 1 auto;

    // Reset h1 styles
    margin: 0;
    color: inherit;
    font-weight: $font-weight-normal;
    line-height: 1;
    font-size: 1rem;

    // Truncate long app name
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    // Hide on smallest screens
    @include media-breakpoint-down(xs) {
        @include sr-only;
    }

    // Fiddling with sizing & positioning
    background: $avi-logotype-background-image left top/auto 1.125rem no-repeat;
    padding-top: 1.275rem;
    min-width: 114/16 * 1rem; // Show full logotype background-image

    @include media-breakpoint-up(md) {
        padding-top: 1.4rem;
    }
}

// Site header nav dropdowns
.avi-site-header-nav-dropdown- {
    $spacing: 10/16 * 1rem;
    $transition-duration: 0.15s;

    &toggle-items {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;

        + .avi-site-header-nav-dropdown-toggle-items {
            margin-right: $spacer;
        }
    }

    &toggle-items-account {
    }

    &toggle-items-notifications {
    }

    &toggle-item {
        + .avi-site-header-nav-dropdown-toggle-item {
            margin-left: $spacer;
        }
    }

    &toggle {
        // outline: 1px solid lightpink;  // Debug
        position: relative;
        display: flex;
        align-items: center;
        width: 2rem; // #ToDo Eventually use smaller icon for smaller screens?
        height: 2rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transition: transform $transition-duration ease-in-out;
        color: inherit;
        cursor: pointer;

        &:hover,
        &[aria-expanded='true'] {
            color: inherit;
            transform: scale(1.1);
        }

        // Truncate long user name
        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
        }

        .badge {
            position: absolute;
            font-size: 10/16 * 1rem;
            top: -0.5em;
            right: -0.4em;
            padding: 0.3em 0.2em;
            min-width: 2.2em;
            background-color: theme-color(info);
            border-radius: 100%;
            border: 0.3em solid $avi-site-header-nav-primary-bg;
        }
    }

    &toggle-account {
        max-width: 110/16 * 1rem; // #ToDo Proper calculation

        @include media-breakpoint-up(sm) {
            width: auto;
        }

        @include media-breakpoint-down(xs) {
            @include avi-background-image-account(
                $avi-site-header-nav-primary-color
            );

            span {
                @include sr-only;
            }
        }
    }

    &toggle-alerts {
        @include avi-background-image-notifications(
            $avi-site-header-nav-primary-color
        );

        .badge {
            background-color: theme-color(danger);
        }
    }

    &toggle-recommendations {
        @include avi-background-image-lightbulb(
            $avi-site-header-nav-primary-color
        );
    }

    // Account menu

    &menu-account {
        a {
            background-repeat: no-repeat;
            background-size: ($line-height-base * $font-size-base)
                ($line-height-base * $font-size-base); // ✅ MSIE11
            background-position: $dropdown-item-padding-x center;
        }
    }

    &menu-link-account {
        padding-left: 2.625rem;
        @include avi-background-image-account($dropdown-link-color);
    }

    &menu-link-sign-out {
        padding-left: 2.625rem;
        @include avi-background-image-logout-variant($dropdown-link-color);
    }

    // Notifications menu

    &menu-notifications {
        padding: 0;
        overflow: auto;

        // Fixed positioning for smallest screens
        @include media-breakpoint-down(xs) {
            border-width: 0;
            // Override element styles calculated and set by Dropdown - hence we have to
            // apply !important 😳
            transform: none !important;
            position: fixed !important;
            top: map-get(
                $avi-site-header-nav-primary-base-heights,
                xs
            ) !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
        }

        @include media-breakpoint-up(sm) {
            width: 460/16 * 1rem;
        }

        @each $breakpoint in sm md lg {
            @include media-breakpoint-up($breakpoint) {
                max-height: calc(
                    100vh - #{map-get(
                            $avi-site-header-nav-primary-base-heights,
                            $breakpoint
                        )}
                );
            }
        }
    }

    &menu-notifications-item {
        border-bottom: 1px solid $border-color;

        @include media-breakpoint-up(sm) {
            &:last-child {
                border-bottom: none;
            }
        }
    }

    &menu-notifications-item-note {
        border-bottom: none;
    }

    &menu-notifications-item-link {
        position: relative;
        padding: $spacing * 0.5 $spacing * 0.75 $spacing * 0.5 $spacing * 1.75;
        transition: background-color $transition-duration ease-in-out;
        color: $body-color;
        cursor: pointer;

        // Animated pseudo border-left
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 4/16 * 1rem;
            content: '';
            background-color: $spindle;
            transition: background-color $transition-duration ease-in-out,
                width $transition-duration ease-in-out;

            // Thick border for touch devices

            // https://css-tricks.com/touch-devices-not-judged-size/#article-header-id-2
            // https://caniuse.com/#feat=css-media-interaction

            @media (hover: none) {
                width: $spacing;
            }
        }

        &:hover {
            background-color: $alabaster;

            &:before {
                width: $spacing;
                background-color: $signal-blue;
            }
        }

        > * {
            padding: $spacing * 0.25;
        }
    }

    &menu-notifications-item-link-alert {
        &:before {
            background-color: $geraldine;
        }

        &:hover {
            &:before {
                background-color: $signal-red;
            }
        }
    }

    &menu-notifications-item-title {
        font-weight: $headings-font-weight;
    }

    &menu-notifications-item-date {
        font-size: $small-font-size; // ^= 80%

        .avi-site-header-nav-dropdown-menu-notifications-item-link > & {
            // Avoid !important
            padding-top: ($spacing * 0.25) + ($font-size-base * 0.2);
        }
    }
} // .avi-site-header-nav-dropdown-

// Site header secondary nav
.avi-site-header-nav-secondary {
    background-color: $avi-site-header-nav-secondary-light-bg;

    @each $breakpoint in xs md lg {
        @include media-breakpoint-up($breakpoint) {
            min-height: map-get(
                $avi-site-header-nav-secondary-heights,
                $breakpoint
            );
        }
    }
}

// Site header tertiary nav
.avi-site-header-nav-tertiary {
    background-color: $avi-site-header-nav-tertiary-dark-bg;

    @each $breakpoint in xs md lg {
        @include media-breakpoint-up($breakpoint) {
            min-height: map-get(
                $avi-site-header-nav-tertiary-heights,
                $breakpoint
            );
        }
    }
}

// Site header secondary nav
// Site header tertiary nav
.avi-site-header-nav-secondary,
.avi-site-header-nav-tertiary {
    display: flex;
    align-items: center;
    overflow: auto;

    .navbar {
        flex: 1 1 100%; // Full width flex item, apparently MSIE safe
    }

    // Optinionated background colors for .navbar
    .navbar {
        background-color: $avi-site-header-nav-secondary-light-bg;
    }
    .navbar-dark {
        background-color: $avi-site-header-nav-secondary-dark-bg;
    }

    .navbar:not([class*='navbar-expand']) {
        padding-left: 0;
        padding-right: 0;

        .navbar-nav {
            .nav-item,
            .nav-link {
                white-space: nowrap;
            }
        }

        //
        // #Hack #ToDo #WorkInProgress #Workaround #TemporarySolution
        // Show .navbar without appropriate .navbar-expand-* class as expanded
        //

        // Excerpt of the "Generate series of `.navbar-expand-*` responsive classes"
        // sequence in bootstrap/scss/_navbar.scss
        flex-flow: row nowrap;
        justify-content: flex-start;

        .navbar-nav {
            flex-direction: row;

            .dropdown-menu {
                position: absolute;
            }

            .dropdown-menu-right {
                right: 0;
                left: auto; // Reset the default from `.dropdown-menu`
            }

            .nav-link {
                padding-right: $navbar-nav-link-padding-x;
                padding-left: $navbar-nav-link-padding-x;
            }
        }

        // For nesting containers, have to redeclare for alignment purposes
        > .container,
        > .container-fluid {
            flex-wrap: nowrap;
        }

        .navbar-collapse {
            display: flex !important;

            // Changes flex-bases to auto because of an IE10 bug
            flex-basis: auto;
        }

        .navbar-toggler {
            display: none;
        }

        .dropup {
            .dropdown-menu {
                top: auto;
                bottom: 100%;
            }
        }
        // End of excerpt of the "Generate series of `.navbar-expand-*` responsive classes"
        // sequence in bootstrap/scss/_navbar.scss

        // Excerpt of the "Generate series of `.navbar-expand-*` responsive classes"
        // sequence in aviatar-bootstrap/scss/bootstrap-overrides/_navbar.scss
        .navbar-brand {
            margin-top: -$navbar-padding-y;
            margin-bottom: -$navbar-padding-y;
            padding-top: $navbar-padding-y + $navbar-brand-padding-y;
            padding-bottom: $navbar-padding-y + $navbar-brand-padding-y;
        }

        .navbar-nav {
            margin-top: -$navbar-padding-y;
            margin-bottom: -$navbar-padding-y;

            .nav-link {
                padding-left: $nav-link-padding-x;
                padding-right: $nav-link-padding-x;
                padding-top: $navbar-padding-y + $nav-link-padding-y;
                padding-bottom: $navbar-padding-y + $nav-link-padding-y;
            }
        }
        // End of excerpt of the "Generate series of `.navbar-expand-*` responsive classes"
        // sequence in aviatar-bootstrap/scss/bootstrap-overrides/_navbar.scss
    } // .navbar:not([class*="navbar-expand"])

    .avi-navbar-variant-1:not([class*='navbar-expand']) {
        // Excerpt of the "Generate series of `.navbar-expand-*` responsive classes"
        // sequence in aviatar-bootstrap/scss/aviatar-custom/_navbar.scss
        .navbar-nav {
            // Bottom border indicates active item
            .show > .nav-link,
            .active > .nav-link,
            .nav-link.show,
            .nav-link.active {
                &:not(.dropdown-toggle) {
                    border-bottom: $avi-navbar-variant-1-active-border-width
                        solid; // Applies text color
                    padding-bottom: $navbar-padding-y + $nav-link-padding-y -
                        $avi-navbar-variant-1-active-border-width;
                }
            }
        }
        // End of excerpt of the "Generate series of `.navbar-expand-*` responsive classes"
        // sequence in aviatar-bootstrap/scss/aviatar-custom/_navbar.scss
    } // .avi-navbar-variant-1:not([class*="navbar-expand"])

    .avi-navbar-variant-2:not([class*='navbar-expand']) {
        .navbar-nav {
            .nav-link {
                padding: (
                        $navbar-padding-y +
                            $avi-navbar-variant-2-nav-link-padding-y
                    )
                    $nav-link-padding-x;
            }
        }

        // Excerpt of the "Generate series of `.navbar-expand-*` responsive classes"
        // sequence in aviatar-bootstrap/scss/aviatar-custom/_navbar.scss
        .navbar-nav {
            // Bottom border indicates active item
            .show > .nav-link,
            .active > .nav-link,
            .nav-link.show,
            .nav-link.active {
                &:not(.dropdown-toggle) {
                    border-bottom: $avi-navbar-variant-2-active-border-width
                        solid; // Applies text color
                    padding-bottom: $navbar-padding-y +
                        $avi-navbar-variant-2-nav-link-padding-y -
                        $avi-navbar-variant-2-active-border-width;
                }
            }
        }
        // End of excerpt of the "Generate series of `.navbar-expand-*` responsive classes"
        // sequence in aviatar-bootstrap/scss/aviatar-custom/_navbar.scss
    } // .avi-navbar-variant-2:not([class*="navbar-expand"])
} // .avi-site-header-nav-secondary, .avi-site-header-nav-tertiary
