// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control-input {
    &:checked ~ .custom-control-label::before {
        border-color: $custom-control-indicator-checked-bg;
    }

    &:disabled:checked ~ .custom-control-label::before {
        border-color: $input-border-color;
    }

    &:focus ~ .custom-control-label::before {
        border-color: $input-focus-border-color;
    }
}

// Custom control indicators
//
// Build the custom controls out of psuedo-elements.

.custom-control-label {
    font-weight: $font-weight-base;

    // Background-color and (when enabled) gradient
    &::before {
        border: 1px solid $input-border-color;
    }
}

// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
    // Checkbox icon variant :disabled:checked
    .custom-control-input:disabled:checked ~ .custom-control-label::after {
        background-image: str-replace(
            url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$gray-dark}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"),
            '#',
            '%23'
        );
    }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
    // Radio icon variant :disabled:checked
    .custom-control-input:disabled:checked ~ .custom-control-label::after {
        background-image: str-replace(
            url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$gray-dark}'/%3E%3C/svg%3E"),
            '#',
            '%23'
        );
    }
}

// Form validation
//

.custom-control-input {
    .was-validated &:valid,
    &.is-valid {
        ~ .custom-control-label::before {
            background-color: $white;
        }

        &:checked ~ .custom-control-label::before {
            background-color: $custom-control-indicator-checked-bg;
        }

        &:focus ~ .custom-control-label::before {
            box-shadow: $custom-control-indicator-focus-box-shadow;
        }
    }

    .was-validated &:invalid,
    &.is-invalid {
        ~ .custom-control-label::before {
            background-color: $white;
            border-color: theme-color('danger');
        }

        &:checked ~ .custom-control-label::before {
            background-color: $custom-control-indicator-checked-bg;
        }

        &:focus ~ .custom-control-label::before {
            border-color: $input-focus-border-color;
            box-shadow: $custom-control-indicator-focus-box-shadow;
        }
    }
}

// File
//
// Custom file input.

.custom-file-input {
    ~ .custom-file-label::after {
        font-weight: $btn-font-weight;
        text-transform: uppercase;
    }
}

.custom-file-label {
    font-weight: normal;
}
