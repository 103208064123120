/*
 * .nav color variants
 *
 * - Dark links against a light background
 * - White links against a dark background
 *
 * Intended for usage as modifier, like
 * .nav.avi-nav-dark or .nav.avi-nav-light
 */
$variants: dark light;

@each $variant in $variants {
    .avi-nav-#{$variant} {
        .nav-link {
            color: avi-nav-color-variant(link, $variant);

            @include hover-focus {
                color: avi-nav-color-variant(hover, $variant);
            }

            &.disabled {
                color: avi-nav-color-variant(disabled, $variant);
            }
        }

        .show > .nav-link,
        .active > .nav-link,
        .nav-link.show,
        .nav-link.active {
            color: avi-nav-color-variant(active, $variant);
        }
    }
}

/*
 * A simple .nav variant
 *
 * Intended for usage with .flex-column, like
 * .nav.flex-column.avi-nav-variant-1
 */
.avi-nav-variant-1 {
    ul {
        list-style-type: none;
        padding-left: 1rem;
    }

    .nav-link {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
}

/*
 * Fancy .nav variant offering .collapse-toggle
 *
 * Intended for usage with .flex-column, like
 * .nav.flex-column.avi-nav-variant-2
 */
.avi-nav-variant-2 {
    ul {
        list-style-type: none;
    }

    .nav-item {
        font-size: $h4-font-size;
    }

    .nav-link {
        border-left: 2px solid transparent;
        padding-left: 1.75rem;
        border-right: 2px solid transparent;
        padding-right: 1rem;
    }

    .nav-link.active,
    .nav-item.active > .nav-link,
    .nav-item.show > .nav-link {
        border-left-color: $eden;
    }

    // 1st level

    > .nav-item > .nav-link {
        text-transform: uppercase;
    }

    // 2nd level

    ul {
        padding-left: 0;

        .nav-link {
            font-weight: $font-weight-base;

            &.collapse-toggle {
                padding-left: 3rem;
                background-position-x: 1.5rem;
            }
        }
    }

    // 3rd level

    ul ul {
        .nav-link {
            padding-left: 3rem;

            &.collapse-toggle {
                padding-left: 4.25rem;
                background-position-x: 2.75rem;
            }
        }
    }

    // 4th level

    ul ul ul {
        .nav-link {
            padding-left: 4.25rem;
        }
    }

    // Collapse component

    .nav-link.collapse-toggle {
        background-repeat: no-repeat;
        background-size: 1.3em 1.3em; // MSIE requires two-value syntax
        background-position-x: 0.3em;
        background-position-y: 0.65em;
        @include avi-background-image-expand-more($patina);

        &.collapsed {
            @include avi-background-image-chevron-right($patina);
        }
    }

    .nav-link.collapse-toggle:hover,
    .nav-link.collapse-toggle.active,
    .nav-item.active > .nav-link.collapse-toggle,
    .nav-item.show > .nav-link.collapse-toggle {
        @include avi-background-image-expand-more($eden);
    }

    .nav-link.collapse-toggle.collapsed:hover,
    .nav-link.collapse-toggle.collapsed.active,
    .nav-item.active > .nav-link.collapse-toggle.collapsed,
    .nav-item.show > .nav-link.collapse-toggle.collapsed {
        @include avi-background-image-chevron-right($eden);
    }

    // Color variants

    @each $variant in $variants {
        &.avi-nav-#{$variant} {
            .nav-link,
            .nav-item.active .nav-link,
            .nav-item.show .nav-link {
                color: avi-nav-color-variant(link, $variant);
            }

            .nav-link:hover,
            .nav-link.active,
            .nav-item.active .nav-link:hover,
            .nav-item.active .nav-link.active,
            .nav-item.show .nav-link:hover,
            .nav-item.show .nav-link.active,
            .nav-item.active > .nav-link,
            .nav-item.show > .nav-link {
                color: avi-nav-color-variant(hover, $variant);
            }

            .nav-link.active,
            .nav-item.active > .nav-link,
            .nav-item.show > .nav-link {
                border-left-color: avi-nav-color-variant(hover, $variant);
            }

            // Collapse component

            .nav-link.collapse-toggle {
                @include avi-background-image-expand-more(
                    avi-nav-color-variant(link, $variant)
                );

                &.collapsed {
                    @include avi-background-image-chevron-right(
                        avi-nav-color-variant(link, $variant)
                    );
                }
            }

            .nav-link.collapse-toggle:hover,
            .nav-link.collapse-toggle.active,
            .nav-item.active > .nav-link.collapse-toggle,
            .nav-item.show > .nav-link.collapse-toggle {
                @include avi-background-image-expand-more(
                    avi-nav-color-variant(hover, $variant)
                );
            }

            .nav-link.collapse-toggle.collapsed:hover,
            .nav-link.collapse-toggle.collapsed.active,
            .nav-item.active > .nav-link.collapse-toggle.collapsed,
            .nav-item.show > .nav-link.collapse-toggle.collapsed {
                @include avi-background-image-chevron-right(
                    avi-nav-color-variant(hover, $variant)
                );
            }
        }
    }
}

// ===== Deprecated ============================================================

// ===== Deprecated in v1.3 ====================================================

.avi-nav-aside-list {
    @extend .avi-nav-variant-1;
}

.avi-nav-main-list {
    @extend .avi-nav-variant-2;
}
