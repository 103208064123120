//
// Textual form controls
//

.form-control {
    // Disabled and read-only inputs
    //
    &:disabled {
        color: $gray-dark;
    }
}

// Readonly controls as plain text
//
.form-control-plaintext {
    display: block;
    width: 100%;
    // // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    // height: $input-height;
    padding: $input-btn-padding-y $input-btn-padding-x;
    font-size: $font-size-base;
    line-height: $input-btn-line-height;
    color: $input-color;
    background-color: $input-bg;
    // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
    background-image: none;
    background-clip: padding-box;
    border: $input-btn-border-width solid $input-bg;
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

.form-control,
.custom-select {
    .was-validated &:valid,
    &.is-valid {
        border-color: $input-border-color;

        &:focus {
            border-color: $input-focus-border-color;
            box-shadow: none;
        }
    }

    .was-validated &:invalid,
    &.is-invalid {
        &:focus {
            border-color: $input-focus-border-color;
            box-shadow: none;
        }
    }
}
