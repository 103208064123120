// Applying em units (not rem) to keep relative proportion (for usage in h1, h2,
// h3 etc.)
.avi-collapse-toggle {
    padding-left: 16/14 * 1.5em; // ^= .pl-3 for h4
    color: $headings-color;
    @include avi-background-image-expand-less($headings-color);
    background-repeat: no-repeat;
    background-size: 1.75em 1.75em; // MSIE requires two-value syntax
    background-position-x: -0.39em;
    background-position-y: -0.3em;

    &:hover {
        color: $headings-color;
    }

    &.collapsed {
        @include avi-background-image-expand-more($headings-color);
    }
}
