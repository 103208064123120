$navbar-nav-nav-item-font-size: $h4-font-size;
$navbar-nav-nav-item-font-size-lg: $h3-font-size;

// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
    font-size: $h2-font-size;

    @include media-breakpoint-up(lg) {
        font-size: $navbar-brand-font-size;
    }
}

// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
    .nav-item {
        font-size: $navbar-nav-nav-item-font-size;

        @include media-breakpoint-up(lg) {
            font-size: $navbar-nav-nav-item-font-size-lg;
        }
    }

    .nav-link {
        padding-right: $nav-link-padding-x;
        padding-left: $nav-link-padding-x;
    }
}

// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            // Compact (small screens below expand breakpoint)
            // Achive full width items with negative margin-x plus corresponding padding-x
            @include media-breakpoint-down($breakpoint) {
                .navbar-nav {
                    margin-top: $nav-link-padding-y;
                    margin-left: -$navbar-padding-x;
                    margin-right: -$navbar-padding-x;

                    &:not(:last-child) {
                        margin-bottom: $nav-link-padding-y;
                    }

                    .nav-link {
                        padding-left: $navbar-padding-x;
                        padding-right: $navbar-padding-x;
                    }
                }
            }

            // Expanded (above expand breakpoint)
            // Achive full height items with negative margin-y plus corresponding padding-y
            @include media-breakpoint-up($next) {
                .navbar-brand {
                    margin-top: -$navbar-padding-y;
                    margin-bottom: -$navbar-padding-y;
                    padding-top: $navbar-padding-y + $navbar-brand-padding-y;
                    padding-bottom: $navbar-padding-y + $navbar-brand-padding-y;
                }

                .navbar-nav {
                    margin-top: -$navbar-padding-y;
                    margin-bottom: -$navbar-padding-y;

                    .nav-link {
                        padding-left: $nav-link-padding-x;
                        padding-right: $nav-link-padding-x;
                        padding-top: $navbar-padding-y + $nav-link-padding-y;
                        padding-bottom: $navbar-padding-y + $nav-link-padding-y;
                    }
                }
            }
        }
    }
}

// Navbar themes
//
// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar-light {
    .navbar-brand {
        color: theme-color('primary');

        @include hover-focus {
            color: theme-color('primary');
        }
    }

    .navbar-nav {
        .nav-link {
            color: $patina;
        }

        .show > .nav-link,
        .active > .nav-link,
        .nav-link.show,
        .nav-link.active {
            background-color: $patina;
        }
    }
}

// ===== Deprecated ============================================================

// ===== Deprecated in v1.3 ====================================================

// CSS Variables (Custom Properties) for dynamic calculation
// Utilized in ../aviatar-custom/_navbar.scss
:root {
    --registration-header-height: 64px;
    --navbar-nav-nav-item-font-size: $navbar-nav-nav-item-font-size;

    @include media-breakpoint-up(lg) {
        --navbar-nav-nav-item-font-size: $navbar-nav-nav-item-font-size-lg;
    }
}
