//
// Lists
//

ul.avi-list {
    padding-left: 1em;

    li {
        list-style-type: none;
        padding-left: 1.25em;

        @include avi-background-image-lens($body-color);
        background-repeat: no-repeat;
        background-size: 0.4em 0.4em; // MSIE requires two-value syntax
        background-position-x: 0.1em;
        background-position-y: 0.5em;

        ul {
            padding-left: 0;
        }

        li {
            padding-left: 1.5em;

            @include avi-background-image-remove($body-color);
            background-size: 1em 1em; // MSIE requires two-value syntax
            background-position-x: 0;
            background-position-y: 0.2em;
        }
    }
}

ol.avi-list {
    padding-left: 2em;

    ol {
        padding-left: 1.25em;
    }
}
