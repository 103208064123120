@import 'styles/bootstrap-overrides/_colors';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.notifications-host {
    position: absolute;
    top: 45px;
    right: 20px;
    color: rgba($tundora, 0.8);
    user-select: none;

    .notification-list {
        z-index: $zindex-fixed;
        display: flex;
        flex-direction: column;
        width: 280px;
        max-height: 320px;
        padding-bottom: 10px;
        overflow-y: auto;
        background-color: $white;
        box-shadow: 0 0 15px rgba($tundora, 0.5);
        border-radius: 3px;
        min-height: 50px;

        .no-notifications-message {
            padding: 12px 0;
            font-size: 12px;
            text-align: center;
            user-select: none;
        }

        staff-loader {
            display: block !important;
            text-align: center !important;
            align-self: center !important;

            .dot {
                width: 10px !important;
                height: 10px !important;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .notifications-host {
        top: 55px;
        right: -58px;

        .notification-list {
            width: calc(100vw - 20px);
            max-height: calc(100vh - 85px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
            min-height: calc(100vh - 85px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        }
    }
}
