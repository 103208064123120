$popover-inner-padding: $input-btn-padding-x; // Dropped in Bootstrap 4 Beta 2

.popover {
    padding: $popover-inner-padding;
    border-radius: 0;
    font-size: $font-size-base;
}

.popover-header {
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    font-weight: 500;
}
