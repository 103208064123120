/**
 * _site-layout.scss
 */

/*
  Site structure:

  .avi-site-layout
    .avi-site-header.avi-site-header-has-nav-primary{,-two-stages,.avi-site-header-has-nav-secondary,.avi-site-header-has-nav-tertiary}
    .avi-site-content-wrap *
      .avi-site-content-header *
      .avi-site-content-area
        .avi-site-content-aside-primary **
        .avi-site-content-main
        .avi-site-content-aside-secondary **
      .avi-site-content-footer *

  *)  Optional, if full-width header and/ or footer is needed around content &
      asides
  **) Optional, if asides are needed for content
*/

// MSIE Flexbugs
//
//
// MSIE Flexbug #1: Minimum content sizing of flex items not honored
//
// By default, flex items won’t shrink below their minimum content size (the length
// of the longest word or fixed-size element).
//
// Workaround: You can usually get this same behavior by setting a flex-shrink
// value of 0 (instead of the default 1) and a flex-basis value of auto. That will
// cause the flex item to be at least as big as its width or height (if declared)
// or its default content size.
//
// https://github.com/philipwalton/flexbugs#flexbug-1
//
//
// MSIE Flexbug #3: min-height on a flex container won't apply to its flex items
//
// Workaround: For cases where min-height is required, the workaround is to add a
// wrapper element around the flex container that is itself a flex container in
// the column direction. For some reason nested flex containers are not affected
// by this bug.
//
// https://github.com/philipwalton/flexbugs#flexbug-3
//
//
// MSIE Flexbug #7: flex-basis doesn't account for box-sizing: border-box
//
// An explicit flex-basis value (i.e., any value other than auto) is supposed to
// act just like width or height. It determines the initial size of a flex item and
// then the other flexibility properties allow it to grow or shrink accordingly. IE
// 10-11 always assume a content box model when using flex-basis to determine a
// flex item's size, even if that item is set to box-sizing: border-box.
//
// Workaround: Instead of setting an explicit flex-basis value, use auto, and then
// set an explicit width or height.
//
// https://github.com/philipwalton/flexbugs#flexbug-7
//

// MSIE Flexbug #3 workaround (may get applied to body)
.avi-site-layout {
    // .d-flex.flex-column
    display: flex;
    flex-direction: column;
}

// Fixed site header, expecting .avi-site-header-has-nav-primary and/ or
// .avi-site-header-has-nav-secondary in addition
.avi-site-header {
    // .fixed-top
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    border-bottom: $avi-site-header-border-bottom-width solid $border-color;
}

// Set viewport height & appropriate padding-top to site-header's adjacent sibling
.avi-site-content-wrap,
.avi-site-content-area {
    .avi-site-header + & {
        min-height: 100vh; // See MSIE Flexbug #3 workaround on .avi-site-layout

        @include media-breakpoint-up(md) {
            // To enable overflow, we need a fixed height
            @if $avi-enable-site-content-overflow {
                height: 100vh;
                max-height: 100vh; // Required for MozFF
            }
        }
    }

    @include avi-site-header-extended-height-successor-derivative(
        '+',
        'padding-top'
    );
}

// Wrapper, optional - required if full-width header and/ or footer is needed
// around content & asides
.avi-site-content-wrap {
    // .d-flex.flex-column
    display: flex;
    flex-direction: column;
}

// Full-width header spanning content & asides, optional
.avi-site-content-header {
    background-color: $avi-site-content-header-bg;
    border-bottom: 1px solid $border-color;
}

// Full-width footer spanning content & asides, optional
.avi-site-content-footer {
    background-color: $avi-site-content-footer-bg;
    border-top: 1px solid $border-color;
}

// Content and asides wrapper
.avi-site-content-area {
    // .d-flex.flex-column.flex-md-row
    display: flex;
    flex-direction: column;

    flex: 1 0 auto; // MSIE Flexbug #1 safe

    @include media-breakpoint-up(md) {
        flex-direction: row;

        @if $avi-enable-site-content-overflow {
            flex: 0
                1
                100%; // MSIE Flexbug #1 safe while flex item has overflow: auto;
        }
    }
}

// Asides for content, optional
.avi-site-content-aside- {
    &primary,
    &secondary {
        flex: 0 0 auto;
        transition: width $avi-site-content-aside-transition-duration
            $avi-site-content-aside-transition-timing-function
            $avi-site-content-aside-transition-delay;

        @include media-breakpoint-up(md) {
            @if $avi-enable-site-content-overflow {
                overflow: auto;
            }
        }

        &.collapsed {
            width: 49/16 * 1rem;
            overflow-x: hidden;
        }
    }

    &primary {
        background-color: $avi-site-content-aside-primary-bg;

        @include media-breakpoint-up(md) {
            width: $avi-site-content-aside-primary-width-md; // MSIE Flexbug #7 workaround: apply width instead of flex-basis
            border-right: 1px solid $border-color;
        }
    }

    &secondary {
        background-color: $avi-site-content-aside-secondary-bg;

        @include media-breakpoint-up(md) {
            width: $avi-site-content-aside-secondary-width-md; // MSIE Flexbug #7 workaround: apply width instead of flex-basis
            border-left: 1px solid $border-color;
        }
    }
}

.avi-site-content-aside-toggle-wrap {
    padding-top: 7/16 * 1rem;
    padding-left: 7/16 * 1rem;
}

.avi-site-content-aside-toggle {
    display: inline-block;
    margin: auto 5/16 * 1rem;
    width: 24/16 * 1rem;
    height: 24/16 * 1rem;
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include avi-background-image-tune($patina);
    cursor: pointer;

    &:hover {
        @include avi-background-image-tune($eden);
    }
}

.avi-site-content-aside-content {
    transition: opacity $avi-site-content-aside-transition-duration
        $avi-site-content-aside-transition-timing-function
        $avi-site-content-aside-transition-delay;

    .collapsed & {
        opacity: 0;
    }
}

// Main content container
.avi-site-content-main {
    flex: 1 0 auto; // MSIE Flexbug #1 safe ...

    background-color: $avi-site-content-main-bg;

    -webkit-overflow-scrolling: touch;

    // ... but in flex-row, we should enable flex-shrink, to be on the safe side
    @include media-breakpoint-up(md) {
        flex: 1 1 auto; // Apparently MSIE Flexbug #1 safe

        @if $avi-enable-site-content-overflow {
            overflow: auto;
        }
    }
}

// Utilities

// Apply basic site header heights derived padding-top
.avi-site-header-derived-padding-top {
    @include avi-site-header-heights-derived-property('padding-top');
}

// Apply basic two staged site header heights derived padding-top
.avi-site-header-two-stages-derived-padding-top {
    @include avi-site-header-two-stages-heights-derived-property('padding-top');
}
