@import 'bootstrap-overrides/_colors';

* {
    word-break: break-word;
}

.carousel-control:hover {
    cursor: pointer;
}

.guidelines {
    margin: 12px 0;

    ul {
        margin-top: 8px;
        margin-left: 32px;
        margin-bottom: 8px;
    }
}

.horizontal-divider {
    padding: 36px 0;

    &::before {
        content: '';
        display: block;
        border-bottom: 1px solid $mediumGrey;
    }
}

.warning-to-changes {
    color: red;
    font-weight: bold;
}

.default-cursor {
    cursor: default;
}
