// Actual modal
.modal-content {
    padding: $modal-inner-padding;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    padding-right: 0;
    padding-left: 0;
}

// Footer (for actions)
.modal-footer {
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.close {
    background-color: rgba($white, 0.4);
    border-radius: 50%;
}
