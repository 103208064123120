body {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
        Helvetica Neue, Arial, sans-serif;
}

.cover-full-height {
    min-height: 100vh;
}

small.validation-error {
    font-size: 12px;
}

p.validation-error {
    font-size: 14px;
}

.validation-error {
    display: inline-block;
    width: 100%;
    color: $crimson;
}


.pointer {
    cursor: pointer;
}

.staffnow-header {
    .staffnow-logo-container {
        width: 100px;
        #logo {
            fill: $lightGrey !important;
        }
    }
}

.splash-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $patina;
    font-size: 22px;
}

staff-loader {
    .splash-screen-loader {
        .dot {
            color: $patina !important;
            width: 20px !important;
            height: 20px !important;
        }
    }
}

$t: 1.5s;
.staff-loader {
    text-align: center;
    margin: auto;
    .dot {
        color: $white;
        display: inline-block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        &:after {
            display: inline-block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: animated $t
                calc(((var(--i) + var(--o, 0)) / var(--n) - 1) * #{$t}) infinite;
        }

        &:after {
            --o: 1;
            background: currentcolor;
            content: '';
        }
    }
}

.purple-loader {
    .dot {
        color: $patina !important;
        width: 20px !important;
        height: 20px !important;
    }
}

@keyframes animated {
    0%,
    50% {
        transform: scale(0);
    }
}

//Default styles for a loader in a button
staff-loader {
    .btn-loader {
        .dot {
            color: $white !important;
            width: 10px !important;
            height: 10px !important;
        }
    }

    .disabled-btn-loader {
        .dot {
            color: $eden !important;
            width: 10px !important;
            height: 10px !important;
        }
    }
}

//Default styles for a loading button
.loading-btn {
    &.loading {
        pointer-events: none;
        staff-loader {
            display: block !important;
        }
        .btn-text {
            display: none;
        }
    }
    staff-loader {
        display: none;
    }
}

.default-avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-size: contain;
    color: white;
    background-color: $patina;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .material-icons {
        font-size: 29px;
    }
}

.notifications-badge {
    position: relative;
}

.ACTIVE {
    color: $green;
}

.PENDING {
    color: $orange;
}

.IN_REVIEW {
    color: $orange;
}

.INCOMPLETE {
    color: $crimson;
}

.DEACTIVATED,
.DELETED {
    color: $crimson;
}

@include media-breakpoint-down(xs) {
    .notifications-opened {
        display: none;
    }
}

.no-cover {
    display: grid;
    align-items: center;
    height: 230px;
    background: $eden;
    user-select: none;
    justify-content: center;

    .material-icons {
        color: $white;
        font-size: 80px;
    }
}

.facility-logo-placeholder {
    text-align: center;
    display: grid;
    padding: 5px;
    width: 100px;
    height: 100px;
    background-color: $gray-dark;
    color: $patina;
    font-weight: bold;
    .logo-text {
        line-height: 21px;
        font-size: 21px;
    }
    .company-text {
        color: $white;
        line-height: 11px;
        font-size: 11px;
    }
}

.facility-profile-modal {
    width: unset;
    .modal-content {
        padding: 0;
    }
}

.date-picker {
    padding-right: 30px !important;
    background-image: url('../assets/images/calendar-icon.svg');
    background-repeat: no-repeat;
    background-position: 96%;
    cursor: pointer;

    &.disabled {
        color: $gray-dark;
        background-color: $lightGrey;
        opacity: 1;
    }
}

.modal-center {
    top: 50%;
    transform: translateY(-50%) !important;
}

.modal-center-horizontal {
    margin: auto;
}

.modal-big {
    max-width: 1000px;
}

.modal-range-picker .modal-content {
    height: 110% !important;
}

.cards-list-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 296px);
    grid-gap: 30px;
    width: 100%;
    height: 100%;
}
