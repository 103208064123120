ng-select {
    width: 100%;
}

.ng-select .ng-select-multiple .ng-select-bottom .ng-select-opened {
    width: 100%;
}

.ng-select .ng-select-container {
    width: 100%;
    border-radius: 2px;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $eden;
    box-shadow: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items {

    .ng-option.ng-option-selected {
        color: $eden !important;
        background-color: rgba($patina, 0.1) !important;
    }

    .ng-option.ng-option-marked {
        color: $white !important;
        background-color: $patina !important;
    }

    .ng-optgroup.ng-option-marked {
        background-color: $patina !important;
    }

}

.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    color: $eden;
    background-color: rgba($patina, 0.1);
    border: 1px solid rgba($patina, 0.2);
}

.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-icon.left {
    border-right: 1px solid rgba($patina, 0.5);

    &:hover {
        background-color: rgba($patina, 0.2);
    }
}

.ng-placeholder {
    padding-top: 2px;
    color: $eden !important;
}
