@import "_environment_variables.scss";

// Color system

// New colors
$eden: #0e6360; // Primary
$tundora: #484848; // Secondary
$patina: #68a694; // Emphasis
$boulder: #7a7a7a; // Text
$nebula: #d2dbd8; // Light background - green
$gallery: #efefef; // Light background - gray
$alternative: #E4973F;
$alternative-darker: #d97015;


$staffNowGreen: #68a694; // StaffNow green
$eLaunchNowBlue: #011445; // eLAUNCHNow blue
$eLaunchNowGreen: #C1D82F; // eLAUNCHNow green
$eLaunchNowGray: #9A9999; // eLAUNCHNow gray


// Change the color if it's in USA
@if $region == usa {
    $eden: $eLaunchNowBlue; // Primary
    $tundora: #484848; // Secondary
    $patina: $eLaunchNowGreen; // Emphasis
    $boulder: #7a7a7a; // Text
    $nebula: #d2dbd8; // Light background - green
    $gallery: #efefef; // Light background - gray
    $alternative: $eLaunchNowGreen;
    $alternative-darker: $eden;

}

// AVIATAR legacy color palette
$black: #172132;
$cyan: #64edce;
$riverBed: #4a5566;
$anthracite: #5c6a7f;
$darkPurple: #474a81;
$mediumPurple: #7375a4;
$lightPurple: #9eacc3;
$darkGrey: #b2babd;
$mediumGrey: #d8d8d8;
$lightGrey: #f5f7fa;
$lightCyan: #bdfdee;
$crimson: #dc322f; // red
$geraldine: #ff9894; // rose
$signalRed: #ff505a;
$laurel: #05a318; // green
$celadon: #90e183; // light green
$denim: #0e74b8; // blue
$spindle: #acc6ea; // light blue
$signalBlue: #09acf8;
$mango: #ff8100; // orange
$buckthorn: #ffbd70; // light orange
$amethyst: #9762c1; // violet
$prelude: #c6aed7; // light violet
$coffee: #8f5749; // brown
$quicksand: #c69c93; // light brown
$carissma: #e873c4; // pink
$lavender: #fbb5d3; // light pink
$pistachio: #bbc000; // lighter green
$chenin: #dbde86; // even more lighter green
$malibu: #00bed1; // teal
$aquamarine: #99dae6; // light teal
$darkAnthrazit: #313d54;
$lightBlue: #b5e6fe;
$lightGreen: #bde4e4;
$lightOrange: #ffe6b2;
$lightRed: #efc4c7;
$white: #fff;

$alabaster: #fafafa;
$anthracite-dark: $darkAnthrazit;
$blue-light: $lightBlue;
$concrete: #f2f2f2;
$cyan-light: $lightCyan;
$gray-dark: $darkGrey;
$gray-light: $lightGrey;
$gray-medium: $mediumGrey;
$green-light: $lightGreen;
$mercury: #e8e8e8;
$mine-shaft: #2b2b2b;
$orange-light: $lightOrange;
$pitch-black: #212121;
$purple-dark: $darkPurple;
$purple-light: $lightPurple;
$purple-medium: $mediumPurple;
$red-light: $lightRed;
$river-bed: $riverBed;
$shark: #333436;
$signal-blue: $signalBlue;
$signal-red: $signalRed;

// $white:    #fff !default;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black:    #000 !default;

// $grays: () !default;
// $grays: map-merge((
//   "100": $gray-100,
//   "200": $gray-200,
//   "300": $gray-300,
//   "400": $gray-400,
//   "500": $gray-500,
//   "600": $gray-600,
//   "700": $gray-700,
//   "800": $gray-800,
//   "900": $gray-900
// ), $grays);

$blue: $spindle;
$indigo: $denim;
$purple: $purple-medium;
$pink: $amethyst;
$red: $signal-red;
$orange: $mango;
$yellow: $buckthorn;
$green: $laurel;
$teal: $celadon;
// $cyan:    #17a2b8 !default;

$colors: (
    'alabaster': $alabaster,
    'amethyst': $amethyst,
    'anthracite': $anthracite,
    'anthracite-dark': $anthracite-dark,
    'aquamarine': $aquamarine,
    'black': $black,
    'blue-light': $blue-light,
    'buckthorn': $buckthorn,
    'carissma': $carissma,
    'celadon': $celadon,
    'chenin': $chenin,
    'coffee': $coffee,
    'concrete': $concrete,
    'crimson': $crimson,
    'cyan': $cyan,
    'cyan-light': $cyan-light,
    'denim': $denim,
    'geraldine': $geraldine,
    'gray-dark': $gray-dark,
    'gray-light': $gray-light,
    'gray-medium': $gray-medium,
    'green-light': $green-light,
    'laurel': $laurel,
    'lavender': $lavender,
    'malibu': $malibu,
    'mango': $mango,
    'mercury': $mercury,
    'mine-shaft': $mine-shaft,
    'orange-light': $orange-light,
    'pistachio': $pistachio,
    'pitch-black': $pitch-black,
    'prelude': $prelude,
    'purple-dark': $purple-dark,
    'purple-light': $purple-light,
    'purple-medium': $purple-medium,
    'red-light': $red-light,
    'river-bed': $river-bed,
    'shark': $shark,
    'signal-blue': $signal-blue,
    'signal-red': $signal-red,
    'spindle': $spindle,
    'quicksand': $quicksand
);
// $colors: map-merge((
//   "blue":       $blue,
//   "indigo":     $indigo,
//   "purple":     $purple,
//   "pink":       $pink,
//   "red":        $red,
//   "orange":     $orange,
//   "yellow":     $yellow,
//   "green":      $green,
//   "teal":       $teal,
//   "cyan":       $cyan,
//   "white":      $white,
//   "gray":       $gray-600,
//   "gray-dark":  $gray-800
// ), $colors);

$primary: $eden;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
$info: $signal-blue;
// $warning:       $yellow !default;
// $danger:        $red !default;
$light: $gray-light;
$dark: $black;

// Card colors
$unseen-color: rgba($denim, 0.7);
$expired-color: rgba($red, 0.8);
$closed-color: rgba($black, 0.9);
