//Here import the component specific styles
@import './ngx-calendar-custom-theme.scss';
@import './ng-custom-multiselect.scss';
@import '../../../../libs/notifications/src/lib/components/notification-list/notification-list.component';
@import './quill-styles';

.app-modal {
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
}

.invite-agencies-modal,
.agencies-list-modal,
.technicians-list-modal {
    @media (max-height: 840px) {
        max-height: 100vh;
        margin: 0 auto;

        .modal-content {
            height: 100%;
        }

        .agencies-list-container {
            height: calc(100vh - 175px) !important;
        }
    }
}

.invite-agencies-modal {
    max-width: 1200px;
}

.agencies-list-modal,
.technicians-list-modal {
    max-width: 738px;
}

.agencies-list-modal,
.technicians-list-modal,
.package-offer-application-modal {
    position: relative;

    .modal-title {
        text-align: center;
        font-size: 26px;
        font-weight: $font-weight-bold;
        margin-bottom: 20px;
    }

    .confirm-btn {
        display: block;
        margin: 20px auto;
    }
}
