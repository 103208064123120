/**
 * AVIATAR flavored Bootstrap theme
 */

// This is the main file importing Bootstrap, Aviatar customizations, Fonts etc.

// Roboto fontface
//
$roboto-font-path: '../fonts' !default;
@import 'node_modules/roboto-fontface/css/roboto/sass/roboto-fontface';

// Override boostrap variables in Sass:
// https://discourse.roots.io/t/how-to-override-boostrap-variables-in-sass/3729/12
//
// Bring your _variables.scss in first. The variables are defined with !default
// in the bootstrap _variables.scss file which means to use their value unless
// the value has already been defined.
//
// Bring in bootstrap
//
// Next mixins - prioritize your overwrites before other components that depend
// on them that you want to overwrite.
//
// Next overwrites (like navbar, buttons, alerts, etc)
//
// Finally, your own styles

// Bootstrap & custom functions - already required in variables
//
@import 'node_modules/bootstrap/scss/functions';
@import 'aviatar-custom/functions';

// Bootstrap & custom variables
//
@import 'bootstrap-overrides/variables';
@import 'node_modules/bootstrap/scss/variables';
@import 'aviatar-custom/variables';

// Bootstrap & custom mixins
//
@import 'node_modules/bootstrap/scss/mixins';
@import 'aviatar-custom/mixins';

// Bootstrap
//
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/code';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/input-group';
@import 'node_modules/bootstrap/scss/custom-forms';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/card';
@import 'node_modules/bootstrap/scss/breadcrumb';
@import 'node_modules/bootstrap/scss/pagination';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/jumbotron';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/progress';
@import 'node_modules/bootstrap/scss/media';
@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/tooltip';
@import 'node_modules/bootstrap/scss/popover';
@import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/utilities';

// Custom overwrites (like navbar, buttons, alerts, etc)
//
@import 'bootstrap-overrides/reboot';
@import 'bootstrap-overrides/type';
@import 'bootstrap-overrides/tables';
@import 'bootstrap-overrides/forms';
@import 'bootstrap-overrides/buttons';
@import 'bootstrap-overrides/dropdown';
@import 'bootstrap-overrides/button-group';
@import 'bootstrap-overrides/input-group';
@import 'bootstrap-overrides/custom-forms';
@import 'bootstrap-overrides/nav';
@import 'bootstrap-overrides/navbar';
@import 'bootstrap-overrides/pagination';
@import 'bootstrap-overrides/alert';
@import 'bootstrap-overrides/list-group';
@import 'bootstrap-overrides/modal';
@import 'bootstrap-overrides/popover';
@import 'bootstrap-overrides/carousel';
@import 'bootstrap-overrides/utilities';

// Custom components styles
//
@import 'aviatar-custom/type';
@import 'aviatar-custom/collapse';
@import 'aviatar-custom/nav';
@import 'aviatar-custom/navbar';
@import 'aviatar-custom/site-layout';
@import 'aviatar-custom/site-header-nav';
@import 'aviatar-custom/utilities';

// Bootstrap print styles
@import 'node_modules/bootstrap/scss/print';

// Custom print styles
@import 'aviatar-custom/print';
