@import 'styles/bootstrap-overrides/_colors';

.bs-datepicker {
    &-head {
        background-color: $patina !important;
    }

    &-body {
        table {
            td {
                span {
                    &.selected {
                        background-color: $patina !important;
                    }
                }
            }
        }
    }
}
