//
// Left/right controls for nav
//

.carousel-control-prev {
    justify-content: flex-start;
}

.carousel-control-next {
    justify-content: flex-end;
}

.carousel-item {
    margin-right: 0;
}


// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators {
    li {
        background-color: transparent;
        background-image: str-replace(
            url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3E%3C/svg%3E"),
            '#',
            '%23'
        ); // material-icons Radio Button Unchecked
    }

    .active {
        background-image: str-replace(
            url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z'/%3E%3C/svg%3E"),
            '#',
            '%23'
        ); // material-icons Lens
    }
}
