.btn-group {
    .btn,
    .btn-group {
        // Horizontal divider
        &:not(:first-child) {
            margin-left: 0;
            border-left: 1px solid $nebula;
        }
    }
}

.btn-group-vertical {
    .btn,
    .btn-group {
        // Vertical divider
        &:not(:first-child) {
            margin-top: 0;
            border-top: 1px solid $nebula;
        }
    }
}
