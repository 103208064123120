// Flex variation
//
// Custom styles for additional flex alignment options.

// ===== Deprecated ============================================================

// ===== Deprecated in v1.3 ====================================================

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .grow#{$infix}-0 {
            flex-grow: 0 !important;
        }
    }
}
