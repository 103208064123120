.nav-link {
    font-weight: $btn-font-weight;
    color: $patina;
}

.nav-link:hover,
.nav-link.active,
.nav-item.active > .nav-link,
.nav-item.show > .nav-link {
    color: $eden;
}

.nav-link.disabled {
    color: $nav-link-disabled-color;
}

.nav-tabs {
    .nav-item:not(:last-child) {
        margin-right: 0.125rem;
    }
}
