/**
 * _print.scss
 */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request:
// http://www.phpied.com/delay-loading-your-print-css/
// ==========================================================================

@if $enable-print-styles {
    @media print {
        // Aviatar Bootstrap components

        .avi-site-header-nav-primary {
            display: none;
        }
    }
}
