//
// Headings
//

h1,
h2,
.h1,
.h2 {
    font-weight: $font-weight-base;
}

// Type display classes
.display-1,
.display-2,
.display-3,
.display-4 {
    color: $eden;
}
